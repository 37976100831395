<template>
  <div>
    <slot name="prepend"></slot>
    <span>{{ $t("allBornInPeriod") }} {{ fromYear }} - {{ toYear }}</span>
  </div>
</template>

<script>
export default {
  name: "BornPeriodDesc",

  props: {
    fromYear: {
      type: Number,
      required: true
    },
    toYear: {
      type: Number,
      required: true
    }
  }
};
</script>
