<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ $t("extractionFromDsf") }}
      </v-card-title>
      <v-card-text>
        <div v-if="loading">
          <p class="text-center">
            {{ $t("dsfExtractLoadInfo") }}
          </p>
          <ProgressLinear :progress="fetchPercent" />
          <div class="mt-6 text-center">
            <v-btn href="/dsf-extract">
              {{ $t("cancel") }}
            </v-btn>
          </div>
        </div>

        <div v-show="!loading">
          <p>
            {{ $t("dsfExtractInfo") }}
          </p>
          <p>
            {{ $t("extractLimitedTo") }}
            <strong>{{ kommuneName }}</strong>
            kommune
          </p>
          <v-alert type="warning">
            <strong>
              {{ $t("dsfExtractInfo2") }}
            </strong>
          </v-alert>

          <div class="text-center mb-4">
            <h3>{{ $t("selectYearRange") }}</h3>
          </div>
          <AgeSlider v-on:change="setAgeRange" />
          <BornPeriodDesc :from-year="fromYear" :to-year="toYear" />

          <v-row>
            <v-col cols="12" md="6" sm="12">
              <AgeDescFromYear :from-year="fromYear" :to-year="toYear" />
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-checkbox
                v-model="inclFnr"
                :label="$t('inclFnr')"
                persistent-hint
                :hint="$t('incFnrHint')"
              />
              <div v-if="inclFnr">
                <v-icon>mdi-alert</v-icon>
                {{ $t("fnrWarning") }}
              </div>
              <v-checkbox
                style="max-width: 450px"
                v-model="use1881"
                :label="$t('use1881AsBackup')"
                id="use1881"
                :hint="$t('use1881AsBackupDesc')"
                persistent-hint
                :disabled="cannotUse1881Lookup"
              />
              <v-alert type="info" v-if="youngestIsYoungster">
                {{ $t("1881Disabled") }}
              </v-alert>
            </v-col>
          </v-row>

          <v-row align="center" justify="center">
            <v-btn
              class="primary mt-4 mb-4"
              @click="extract"
              :disabled="!inputsAreValid"
              large
            >
              {{ $t("run") }}
            </v-btn>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogResults"
      v-if="dialogResults"
      :max-width="dialogMax"
    >
      <v-card>
        <v-card-title>
          <BornPeriodDesc :from-year="fromYear" :to-year="toYear">
            <template v-slot:prepend> {{ $t("result") }} -&nbsp; </template>
          </BornPeriodDesc>
        </v-card-title>
        <v-card-text>
          <v-snackbar v-model="snackbar" top absolute>
            {{ $t("fileDownloaded") }}
          </v-snackbar>
          <AgeDescFromYear :from-year="fromYear" :to-year="toYear" />
          <p class="mt-6 subtitle-1">
            <strong>
              {{ $tc("extractContainsXpers", contacts.total) }}
            </strong>
          </p>
          <ExportBtn
            class="mb-4"
            :outlined="false"
            :primary="true"
            v-on:click="exportAll"
            :text="$t('exportAll')"
          />

          <v-row>
            <v-col cols="12" lg="4" sm="12">
              <ContactImportActionCard
                :contacts-to-import="contacts.withContactData"
                :contacts-not-valid-for-import="contacts.withOutContactData"
                type="contactData"
                :export-file-name-prefix="fileNamePrefix"
              />
            </v-col>
            <v-col cols="12" lg="4" sm="12">
              <ContactImportActionCard
                :contacts-to-import="contacts.withMobile"
                :contacts-not-valid-for-import="contacts.withoutMobile"
                type="mobile"
                :export-file-name-prefix="fileNamePrefix"
              />
            </v-col>
            <v-col cols="12" lg="4" sm="12">
              <ContactImportActionCard
                :contacts-to-import="contacts.withEmail"
                :contacts-not-valid-for-import="contacts.withoutEmail"
                type="email"
                :export-file-name-prefix="fileNamePrefix"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogResults = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTooMany" max-width="550">
      <UserError v-on:ok="dialogTooMany = false">
        <template v-slot:text>
          {{ $t("tooManyDsfExtract") }}
        </template>
      </UserError>
    </v-dialog>
  </v-container>
</template>

<script>
import apiDsf from "@/api/v24/api.dsf";
import UserError from "@/components/misc/UserError";
import AgeSlider from "@/components/misc/AgeSlider";
import LoadingSpinner from "@/components/misc/LoadingSpinner";
import store from "@/store";
import Tooltip from "@/components/misc/Tooltip";
import ProgressLinear from "@/components/misc/ProgressLinear";
import ContactImportActionCard from "@/components/misc/ContactImportActionCard";
import BornPeriodDesc from "@/components/age/BornPeriodDesc";
import AgeDescFromYear from "@/components/age/AgeDescFromYear";
import config from "@/config/config";
import dsf from "@/util/dsf";
import ExportBtn from "@/components/misc/ExportBtn";
import i18n from "@/i18n";
import age from "@/util/age";
import eventLogger from "@/util/eventLogger";

export default {
  name: "DsfExtract",
  components: {
    ExportBtn,
    AgeDescFromYear,
    BornPeriodDesc,
    ContactImportActionCard,
    ProgressLinear,
    Tooltip,
    LoadingSpinner,
    AgeSlider,
    UserError
  },

  data: () => ({
    kommuneNum: null,
    kommuneName: "",
    fromYear: 0,
    toYear: 0,
    dialogTooMany: false,
    dialogResults: false,
    dialogChildCustInfo: false,
    loading: true,
    use1881: false,
    fetchPercent: 0,
    fetchResult: null,
    contacts: null,
    numberOfYearsToFetch: 0,
    numberOfYearsFetched: 0,
    inclFnr: false,
    dialogMax: config.dialogWith.xLarge,
    dialogMedium: config.dialogWith.medium,
    snackbar: false
  }),

  computed: {
    inputsAreValid() {
      return !!(this.fromYear && this.toYear);
    },
    fileNamePrefix() {
      return "Innbyggere_" + this.fromYear + "-" + this.toYear + "_";
    },
    oldestIsMyndigAge() {
      return age.getAgesInYear(this.fromYear).oldest >= 18;
    },
    youngestIsYoungster() {
      return age.getAgesInYear(this.toYear).youngest <= 16;
    },
    cannotUse1881Lookup() {
      return this.youngestIsYoungster;
    }
  },

  created() {
    store.dispatch("updateUserProfile").then(userProfile => {
      this.kommuneNum = userProfile.Kommunenr;
      this.kommuneName = userProfile.Kommunenavn;
      this.loading = false;
    });
  },

  methods: {
    setAgeRange(ageRange) {
      this.fromYear = ageRange[0];
      this.toYear = ageRange[1];
      this.use1881 = false;
    },
    extract() {
      eventLogger.ageFilter(this.fromYear, this.toYear, this.kommuneNum);
      this.loading = true;
      // Start with a "fake" small number so that it looks like that process
      // has started
      this.fetchPercent = 5;
      apiDsf
        .getByAgeCount(this.kommuneNum, this.fromYear, this.toYear)
        .then(res => {
          console.log("Amount of people in age range: " + res.Antall);
          if (res.Antall > 25000) {
            this.dialogTooMany = true;
            this.loading = false;
            return;
          }

          this.numberOfYearsToFetch = this.toYear - this.fromYear + 1;
          this.numberOfYearsFetched = 0;
          this.fetchResult = [];
          this.fetchResult.length = 0;
          this.contacts = {
            total: 0,
            withContactData: [],
            withOutContactData: [],
            withMobile: [],
            withoutMobile: [],
            withEmail: [],
            withoutEmail: []
          };
          this.fetchYearsSynchronous(this.fromYear, this.toYear);
        });
    },

    /**
     * We make synchronous calls so that we don't overload the server with
     * heavy requests
     * @param fromYear
     * @param toYear
     */
    fetchYearsSynchronous(fromYear, toYear) {
      console.log("Fetching for year " + fromYear);
      this.loading = true;
      const that = this;
      fetchData(
        this.kommuneNum,
        fromYear,
        fromYear,
        this.inclFnr,
        this.use1881
      ).then(data => {
        that.numberOfYearsFetched++;
        that.fetchResult = that.fetchResult.concat(data);
        that.fetchPercent =
          (that.numberOfYearsFetched / that.numberOfYearsToFetch) * 100;
        if (fromYear < toYear) {
          const nextYear = fromYear + 1;
          // Call ourself with new year. Since this is done when promise is
          // complete, we line up these calls synchronous
          that.fetchYearsSynchronous(nextYear, toYear);
        } else {
          that.contacts.total = that.fetchResult.length;
          that.fetchResult.forEach(person => {
            if (!that.inclFnr) {
              person.OffId = "";
            }
            if (person.Mobil || person.Epost) {
              that.contacts.withContactData.push(person);
            } else {
              that.contacts.withOutContactData.push(person);
            }

            if (person.Mobil) {
              that.contacts.withMobile.push(person);
            } else {
              that.contacts.withoutMobile.push(person);
            }

            if (person.Epost) {
              that.contacts.withEmail.push(person);
            } else {
              that.contacts.withoutEmail.push(person);
            }
          });

          eventLogger.dsfExctraction(that.fetchResult.length);

          // Just a tiny delay so it's possible to see that progress indicator
          // has reached 100%
          setTimeout(() => {
            that.loading = false;
            that.dialogResults = true;
          }, 500);
        }
      });
    },
    exportAll(usrOptions) {
      dsf.export(this.fetchResult, this.fileNamePrefix, usrOptions.useExcel);
      this.snackbar = true;
    }
  }
};

const fetchData = (kommuneNum, fromYear, toYear, inclFnr, use1881) => {
  return new Promise(resolve => {
    apiDsf.getByAge(kommuneNum, fromYear, toYear, use1881, false).then(res => {
      resolve(res.Innbyggere);
    });
  });
};
</script>
