<template>
  <v-range-slider
    v-model="range"
    :max="maxYear"
    :min="minYear"
    v-on:change="onChange"
    :ticks="true"
  >
    <template v-slot:prepend>
      <v-text-field
        :value="range[0]"
        class="mt-0 pt-0"
        hide-details
        single-line
        type="number"
        style="width: 60px"
        @change="
          $set(range, 0, $event);
          onChange();
        "
      />
    </template>
    <template v-slot:append>
      <v-text-field
        :value="range[1]"
        class="mt-0 pt-0"
        hide-details
        single-line
        type="number"
        style="width: 60px"
        @click="onChange"
        @change="
          $set(range, 1, $event);
          onChange();
        "
      />
    </template>
  </v-range-slider>
</template>

<script>
export default {
  name: "AgeSlider",

  data: () => ({
    minYear: 1900,
    maxYear: new Date().getFullYear(),
    range: [1940, 1945]
  }),

  created() {
    this.onChange();
  },

  methods: {
    onChange() {
      this.$emit("change", [parseInt(this.range[0]), parseInt(this.range[1])]);
    }
  }
};
</script>

<style scoped></style>
