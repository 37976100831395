<template>
  <v-card>
    <v-card-title>
      {{ getTypeDescription(true) }}
    </v-card-title>
    <v-card-text>
      <v-snackbar v-model="snackbarFileDownload" top absolute>
        {{ $t("fileDownloaded") }}
      </v-snackbar>
      <v-snackbar v-model="snackbarGroupImported" top absolute>
        {{ $t("groupImported") }}
      </v-snackbar>
      <PieChart :data="pieData" class="mb-4" />
      <v-btn class="info" block @click="setUpAlert">
        {{ $t("createAlert") }}
      </v-btn>
      <v-btn
        class="info mt-2"
        block
        @click="createGroup"
        v-if="!hideGroupCreationOption"
      >
        {{ $t("createGroup") }}
      </v-btn>
      <ExportBtn
        class="mt-2"
        :info-btn="true"
        :block="true"
        :info="true"
        :outlined="false"
        v-on:click="exportPersonsWithData"
      />
      <ExportBtn
        :block="true"
        class="mt-2"
        @click="exportPersonsWithoutData"
        :outlined="false"
        :small="true"
        :text="$t('export') + ' ' + getTypeDescription(false).toLowerCase()"
      />
    </v-card-text>
    <v-dialog v-model="dialogCreateGroup" :max-width="maxDialogWith">
      <NewGroupForm
        v-on:done="addMembersToNewGroup"
        v-on:cancel="dialogCreateGroup = false"
      />
    </v-dialog>
    <v-dialog v-model="dialogInvalidContacts" :max-width="maxDialogWith">
      <v-card>
        <v-card-title>
          {{ $t("info") }}
        </v-card-title>
        <v-card-text>
          <p>
            {{ $t("contactsInvalidForImportInfo") }}
          </p>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("name") }}
                  </th>
                  <th class="text-left">
                    {{ $t("mobile") }}
                  </th>
                  <th class="text-left">
                    {{ $t("email") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in invalidContacts">
                  <td>{{ item.Navn }}</td>
                  <td>{{ item.Mobil }}</td>
                  <td>{{ item.Epost }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogInvalidContacts = false">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTooManyToAlert" :max-width="maxDialogWith">
      <v-card>
        <v-card-title>
          {{ $t("saveAsNewGrp") }}
        </v-card-title>
        <v-card-text>
          {{ $t("tooLargeGrpToAlert") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogTooManyToAlert = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn @click="createGroup" class="primary">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import store from "@/store";
import router from "@/router";
import PieChart from "@/components/misc/PieChart";
import config from "@/config/config";
import i18n from "@/i18n";
import NewGroupForm from "@/components/groups/NewGroupForm";
import Contacts from "@/util/Contacts";
import Contact from "@/util/Contact";
import PhoneNumber from "@/util/PhoneNumber";
import ContactSource from "@/util/ContactSource";
import apiContact from "@/api/v24/api.contact";
import dsf from "@/util/dsf";
import ExportBtn from "@/components/misc/ExportBtn";

export default {
  name: "ContactImportActionCard",
  components: { ExportBtn, NewGroupForm, PieChart },
  props: {
    /*
    Contacts must have at least these fields
    {
    Adresse: "",
    Epost: "",
    Mobil: "",
    Navn: "",
    },
     */
    contactsToImport: {
      type: Array,
      default: []
    },
    contactsNotValidForImport: {
      type: Array,
      default: []
    },
    // Set to one of these values:
    // "contactData" Input data consists of persons with/without contact data.
    // "mobile" Input data consists of persons with/without mobile number.
    // "email" Input data consists of persons with/without email address.
    type: {
      type: String,
      required: true
    },
    exportFileNamePrefix: {
      type: String,
      default: ""
    },
    hideGroupCreationOption: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    pieData() {
      return {
        datasets: [
          {
            data: [
              this.contactsToImport.length,
              this.contactsNotValidForImport.length
            ],
            backgroundColor: [
              config.chartColors.strongGreen,
              config.chartColors.grey
            ]
          }
        ],
        labels: [this.getTypeDescription(true), this.getTypeDescription(false)]
      };
    }
  },

  data: () => ({
    maxDialogWith: config.dialogWith.medium,
    dialogCreateGroup: false,
    dialogInvalidContacts: false,
    dialogTooManyToAlert: false,
    snackbarFileDownload: false,
    snackbarGroupImported: false,
    invalidContacts: []
  }),

  methods: {
    getTypeDescription(withData, prefix = "") {
      const persWithOrWithout = withData
        ? this.$t("persWith")
        : this.$t("persWithout");
      return prefix + persWithOrWithout + " " + importTypes[this.type];
    },
    createGroup() {
      this.dialogTooManyToAlert = false;
      this.dialogCreateGroup = true;
    },
    addMembersToNewGroup(newGroupProps) {
      this.dialogCreateGroup = false;
      const normRes = normalizeContacts(this.contactsToImport);
      this.invalidContacts = normRes.invalidContacts;
      apiContact.createContactsAndAddToGroup(
        normRes.contacts,
        newGroupProps.Id
      );
      this.snackbarGroupImported = true;
      if (this.invalidContacts.length) {
        this.dialogInvalidContacts = true;
      }
    },
    exportPersonsWithData(usrOptions) {
      const fileName = this.getTypeDescription(true, this.exportFileNamePrefix);
      this.exportPersons(this.contactsToImport, fileName, usrOptions.useExcel);
    },
    exportPersonsWithoutData(usrOptions) {
      const fileName = this.getTypeDescription(
        false,
        this.exportFileNamePrefix
      );
      this.exportPersons(
        this.contactsNotValidForImport,
        fileName,
        usrOptions.useExcel
      );
    },
    exportPersons(persons, fileName, useExcel) {
      dsf.export(persons, fileName, useExcel);
      this.snackbar = true;
    },
    setUpAlert() {
      if (this.contactsToImport.length > config.alertContactsPerGroupLimit) {
        this.dialogTooManyToAlert = true;
        return;
      }
      const normalized = normalizeContacts(this.contactsToImport);
      store.commit("setAlertReceivers", normalized.contacts.getAsApiFormat());
      router.push({ path: "/alert" });
    }
  }
};

const normalizeContacts = contactsToImport => {
  const contacts = new Contacts();
  const invalidContacts = [];
  let id = 0;
  contactsToImport.forEach(contact => {
    const c = new Contact(contact.Navn);
    if (contact.Mobil) {
      const pn = new PhoneNumber(contact.Mobil);
      if (!pn.isValid()) {
        invalidContacts.push(contact);
        return;
      }
      const phoneObj = pn.getAsApiFormat();
      c.setMobile1(phoneObj.countryCode + phoneObj.number, phoneObj.numberType);
      }
    if (contact.Epost) {
      c.setEmail(contact.Epost);
    }
    if (contact.OffId) {
      c.setOtherInfo(contact.OffId);
    }
    if (contact._childInfo) {
      let info = "";
      if (contact.OffId) {
        info += "Fødselsnummer: " + contact.OffId + " ";
      }
      info += contact._childInfo;
      c.setOtherInfo(info);
    }
    const contactSource = new ContactSource();
    contactSource.setImport(id++);
    c.setSource(contactSource);
    contacts.add(c);
  });

  return {
    contacts: contacts,
    invalidContacts: invalidContacts
  };
};

const importTypes = {
  contactData: i18n.t("contactData").toLowerCase(),
  mobile: i18n.t("mobile").toLowerCase(),
  email: i18n.t("email").toLowerCase()
};
</script>
