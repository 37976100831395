<template>
  <div>
    <strong>{{ $t("correspondsToAge") }}:</strong><br />
    {{ $t("fromAndIncluding") }}:
    {{ $t("XOldThisYear", { age: fromAge, year: thisYear }) }}<br />
    {{ $t("toAndIncluding") }}:
    {{ $t("XOldThisYear", { age: toAge, year: thisYear }) }}<br />
  </div>
</template>

<script>
import age from "@/util/age";

export default {
  name: "AgeDescFromYear",

  props: {
    fromYear: {
      type: Number,
      required: true
    },
    toYear: {
      type: Number,
      required: true
    }
  },

  computed: {
    thisYear() {
      const d = new Date();
      return d.getFullYear().toString();
    },
    fromAge() {
      return age.getAgesInYear(this.toYear).oldest;
    },
    toAge() {
      return age.getAgesInYear(this.fromYear).oldest;
    }
  }
};
</script>
