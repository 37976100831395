const age = {
    getAgesInYear(year) {
        year = parseInt(year, 10);
        const thisYear = new Date().getFullYear();
        const youngest = thisYear - year;
        const oldest = thisYear - year - 1;
        return {
            oldest: (youngest >= 0) ? youngest : 0,
            youngest: (oldest >= 0) ? oldest : 0
        };
    }
}

export default age;