import config from "@/config/config";
import Export from "@/util/Export";
import arrayUtil from "@/util/arrayUtil";

/**
 * DSF utils.
 * DSF = Det sentrale folkeregisteret.
 */
const dsf = {
  /**
   * Exports data from DSF to CSV or Excel file
   * @param {Array} data As returned from API
   * @param {String} fileName
   */
  export(data, fileName, useExcel) {
    fileName = fileName.replace(" ", "_");
    let exportData = [];
    const childInfoExits = arrayUtil.fieldNameExitsInObjectArray(
      data,
      "_childName"
    );
    data.forEach(person => {
      const source =
        person.Kilde === "1881" ? "Opplysningen 1881" : person.Kilde;
      let csvHack = "";
      if (!useExcel) {
        // Adding  \t (tab) on fields with numbers that can start with
        // 0. This is to prevent Excel of removing the 0
        csvHack = "\t";
      }
      let personData = [
        person.Navn,
        person.Mobil,
        person.Epost,
        source,
        csvHack + person.FodtDato,
        person.FodtAar,
        csvHack + person.OffId,
        person.Adresse,
        csvHack + person.Postnr,
        person.Poststed,
        person.Kommune,
        person._childInfo || ""
      ];
      if (childInfoExits) {
        personData = personData.concat([
          person._childName,
          person._childBorn,
          person._childBornYear
        ]);
      }
      exportData.push(personData);
    });

    const separator = config.getLocaleProperties().csvSeparator;
    const headers = [
      "Navn",
      "Mobil",
      "Epost",
      "Kilde",
      "Fødselsdato",
      "Årstall født",
      "Fødselsnummer",
      "Adresse",
      "Postnummer",
      "Poststed",
      "Kommune",
      "Kommentar"
    ];
    if (childInfoExits) {
      headers.push("Barn navn", "Barn født", "Barn født årstall");
    }
    const exporter = new Export(fileName, exportData);
    exporter.setHeaders(headers);
    if (useExcel) {
      exporter.exportAsExcel();
    } else {
      exporter.exportAsCsvFile(separator);
    }
  }
};

export default dsf;
