<template>
  <v-progress-linear
    :value="progress"
    height="20"
    striped
    stream
    :buffer-value="progress"
  >
  </v-progress-linear>
</template>

<script>
export default {
  name: "ProgressLinear",

  props: {
    progress: {
      type: Number,
      required: true
    }
  }
};
</script>
